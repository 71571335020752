import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ['dropdownMenu', 'form', 'hiddenInput'];

  openDropdown(e) {
    if ($('ul.open')[0] !== this.dropdownMenuTarget) $('ul.open').removeClass('open')
    this.dropdownMenuTarget.classList.toggle('open');
  }

  duplicateWithSettingsOnly(e) {
    e.preventDefault();
    if (e.target.getAttribute('disabled')) return;
    this.sendAjaxNew('settings_only');
  }

  duplicateWithContent(e) {
    e.preventDefault();
    if (e.target.tagName == 'SPAN' || e.target.getAttribute('disabled')
      || e.target.parentNode.getAttribute('disabled')) {
      return;
    }
    this.sendAjaxNew('settings_and_content');
  }

  sendAjaxNew(basedonType) {
    $("a.copy-course").attr('disabled',true);
    this.showDuplicateCourseBox();
    const createDuplicateCourse = this.createDuplicateCourse;

    $.ajax({
      type: "GET",
      url: this.formTarget.getAttribute('action'),
      success: function(data) {
        createDuplicateCourse(data, basedonType);
      },
      error: function(data, textStatus, err) {
        $("span.text").append('<div>' + textStatus + '</div>');
        $("div.notification").show()
        $("#loaderBox").toggle();
      }
    })
  }

  createDuplicateCourse(data, basedonType) {
    $("#loaderBox label")[0].innerHTML = I18n.create_course;
    data = extractCourseParams(data.bundle, data.school, data.basedon, data.payment_info_attributes, data.comments_plugin_attributes, basedonType, data.translation_id);

    $.ajax({
      type: "POST",
      url: "/courses",
      data: data,
      success: function(item) {
        $('#courses-list').append(item);
        $.ajax({
          type: "GET",
          url: "/courses",
          success: function(response) {
            $('#courses-list').html($(response).find('#courses-list').html());
            $('html,body').animate({ scrollTop: document.body.scrollHeight });

            const selectedValue = document.getElementById('courseStatusDropdown').innerText;

            const parser = new DOMParser();
            const doc = parser.parseFromString(item, "text/html");

            const courseEditLink = doc.querySelector("#course-edit-settings-link");
            const hrefValue = courseEditLink.getAttribute("href");
            if (selectedValue === "קורסים פעילים") {
              $('.course-duplicate-toast-message')
                .css({
                  'width': '512px',
                  'border': '1px solid #F3A120',
                  'border-right': '6px solid #F3A120'
                });

              $('.course-toast-done-img')
                .css({
                  'width': '5%',
                });

              $('.course-toast-cross-img')
                .css({
                  'width': '6%',
                });

              $('.course-toast-done-img').attr('src', '/images/duplicate-draft.svg');

              $('.course-duplicate-top-text').text('הקורס שוכפל בהצלחה והוא בסטטוס טיוטה');
              $('.course-duplicate-bottom-text').text('מעבר להגדרות הקורס');
              const link = `<a href="${hrefValue}" target="_blank">  לחץ כאן </a>`;
              $('.course-duplicate-bottom-link').append(link);
            }
            $('.course-duplicate-toast-message').css('display', 'flex');
          },
          error: function() {
            alert("Failed to reload courses list.");
          }
        });
      },
      error: function(data, textStatus, err) {
        $("span.text").html(data.responseText);
        $("div.notification").show()
      },
      complete: function() {
        $("a.copy-course").removeAttr('disabled');
        $("#loaderBox").toggle();
      }
    });
  }

  showDuplicateCourseBox(e) {
    $("#loaderBox label")[0].innerHTML = I18n.duplicate_course;
    $("#loaderBox").toggle();
  }
}

function extractCourseParams(bundle, school, basedon, payment_info_attributes, comments_plugin_attributes, basedonType, basedOnTranslationId) {
  return {
    basedon: basedon.toString(),
    basedon_type: basedonType,
    basedon_translation_id: basedOnTranslationId,
    bundle: {
      name: 'Copy of ' + bundle.name,
      description: bundle.description,
      price: bundle.price,
      currency_type: bundle.currency_type,
      slug: bundle.slug,
      policy: bundle.policy,
      responder_list_paid: bundle.responder_list_paid,
      responder_list_subscribers: bundle.responder_list_subscribers,
      responder_list_graduates: bundle.responder_list_graduates,
      responder_import_list: bundle.responder_import_list,
      instructor_intro: bundle.instructor_intro,
      audience: bundle.audience,
      hidden: true,
      move_to_next_lesson_after_video_ends: bundle.move_to_next_lesson_after_video_ends,
      instructor_name_for_display: bundle.instructor_name_for_display,
      instructor_name_for_email: bundle.instructor_name_for_email,
      instructor_email: bundle.instructor_email,
      expires_after: bundle.expires_after,
      bind_to_ip: bundle.bind_to_ip,
      drip: bundle.drip,
      requires_explicit_school: bundle.requires_explicit_school,
      device_limit: bundle.device_limit,
      device_threshold_limit: bundle.device_threshold_limit,
      published_once: false,
      comments_plugin_attributes: comments_plugin_attributes,
      schools_attributes: {
        '0': {
          afterjoin_url: school.afterjoin_url,
          signup_field_type_phone: school.signup_fields.phone,
          signup_field_type_name: school.signup_fields.name,
          signup_email_text: school.signup_email_text,
          instructor_name_for_email: school.instructor_name_for_email,
          instructor_email: school.instructor_email,
          welcome_email_subject: school.welcome_email_subject,
          payment_info_attributes: payment_info_attributes
        }
      }
    }
  }
}
